export const noShowSummaryState = {
  incidents: [],
  loading: false,
  progress: 0,
  tasker_status: {
    open_modal: false,
    state: null,
  },
  warning: {
    open_modal: false,
  },
  incident_details: {
    open_modal: false,
    current_incident: null,
  },
  error: {
    status: null,
    message: null,
  },
};
