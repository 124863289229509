const Bugsnag = require('@bugsnag/js');

const stages = ['production', 'preview'];
const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';

const bugsnagConfig = {
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
  enabledReleaseStages: stages,
  appVersion: isProduction
    ? process.env.NEXT_PUBLIC_APP_VERSION
    : process.env.DEPLOY_ID,
};

const getBugsnag = () => {
  if (stages.includes(process.env.NEXT_PUBLIC_APP_ENV)) {
    return Bugsnag.start(bugsnagConfig);
  }
  return {
    // skip bugsnag in development and test environments
    notify: () => {},
  };
};

module.exports = {
  bugsnagConfig,
  bugsnag: getBugsnag(),
};
