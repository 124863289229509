import { createSlice } from '@reduxjs/toolkit';

import { AboutState } from './about.state';
import { getAboutTasker } from './about.service';

export const aboutSlice = createSlice({
  name: 'about',
  initialState: AboutState,
  extraReducers: ({ addCase }) => {
    addCase(getAboutTasker.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    addCase(getAboutTasker.rejected, (state, { payload }) => {
      state.loading = false;
      state.error.status = payload?.status;
      state.error.message = payload?.data?.message;
    });
  },
});

export const aboutActions = aboutSlice.actions;
export const aboutSelector = state => state.about;

export default aboutSlice;
