import { createSlice } from '@reduxjs/toolkit';

import { myPreferentialState } from './my-preferentials.state';
import {
  deleteMyPreferentials,
  getMyPreferentials,
  getMyPreferentialsDetails,
} from './my-preferentials.service';

export const myPreferentialSlice = createSlice({
  name: 'my_preferentials',
  initialState: myPreferentialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getMyPreferentials.pending, state => {
      state.loading = true;
      state.preferentials = [];
    });

    addCase(getMyPreferentials.fulfilled, (state, { payload }) => {
      state.preferentials = payload?.data;
      state.loading = false;
    });

    addCase(getMyPreferentials.rejected, (state, { payload }) => {
      state.preferentials = [];
      state.error.status = payload?.status;
      state.error.message = payload?.data?.message;
      state.loading = false;
    });

    addCase(getMyPreferentialsDetails.fulfilled, (state, { payload }) => {
      state.preferentialDetails = {
        loading: false,
        data: payload?.data,
        error: {
          status: null,
          message: null,
        },
      };
    });

    addCase(getMyPreferentialsDetails.rejected, (state, { payload }) => {
      state.preferentialDetails = {
        loading: false,
        data: {},
        error: {
          status: payload?.status,
          message: payload?.data?.message,
        },
      };
    });

    addCase(getMyPreferentialsDetails.pending, state => {
      state.preferentialDetails = {
        loading: true,
        data: {},
        error: {
          status: null,
          message: null,
        },
      };
    });

    addCase(deleteMyPreferentials.fulfilled, (state, { payload }) => {
      state.removePreferential = {
        success: true,
        loading: false,
        data: payload?.data,
        message: payload?.data?.message,
        error: {
          status: null,
          message: payload?.data?.message,
        },
      };
    });

    addCase(deleteMyPreferentials.pending, state => {
      state.removePreferential = {
        success: false,
        loading: true,
        data: {},
        message: null,
        error: {
          status: null,
          message: null,
        },
      };
    });

    addCase(deleteMyPreferentials.rejected, (state, { payload }) => {
      state.removePreferential = {
        success: false,
        loading: false,
        data: {},
        message: null,
        error: {
          status: payload?.status,
          message: payload?.data?.message,
        },
      };
    });
  },
});

export const myPreferentialsActions = myPreferentialSlice.actions;
export const myPreferentialsSelector = state => state.my_preferentials;
