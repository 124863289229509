import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@providers/api.provider';
import { bugsnag } from '@/core/config/bugsnag.config';

/**
 * @description Gets the no show summary from the url: /v2/taskers/no-show-summary.
 *
 * @returns {Object} Returns an object containing the information about
 * tasker status and incidents.
 *
 * @example
 * Response example:
 * {
 *  tasker: Object {
 *    state: string,
 *  },
 *  incidents: Array<Object> [{
 *    incident_id: string,
 *    job_date: Date,
 *    service: string,
 *    penalty_amount: number,
 *    order_number: string,
 *    user_name: string,
 *  }]
 * }
 *
 */
export const getNoShowSummary = createAsyncThunk(
  'no_show_summary/getNoShowSummary',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/v2/taskers/no-show-summary');

      return response.data?.data;
    } catch (error) {
      // TODO: Check why it is not working in a test environment
      if (process.env.NODE_ENV !== 'test') {
        bugsnag.setContext('Client: Request to get no show summary');
        bugsnag.notify(new Error(error));
      }

      if (error.response)
        return rejectWithValue({
          data: error.response.data,
          status: error.response.status,
        });

      return rejectWithValue({
        data: { message: error.message },
      });
    }
  },
);
