import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@providers/api.provider';
import { bugsnag } from '@/core/config/bugsnag.config';

export const getMyPreferentials = createAsyncThunk(
  'my_preferentials/getMyPreferentials',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/v2/taskers/preferentials');

      return response?.data;
    } catch (error) {
      const shouldNotifyBugsnag =
        process.env.NODE_ENV !== 'test' && bugsnag.setContext && bugsnag.notify;

      if (shouldNotifyBugsnag) {
        bugsnag.setContext('Client: Request to get my preferentials');
        bugsnag.notify(new Error(error));
      }

      return rejectWithValue({
        data: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const getMyPreferentialsDetails = createAsyncThunk(
  'my_preferentials/getMyPreferentialsDetails',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/v2/taskers/preferentials/${orderId}`);

      return response?.data;
    } catch (error) {
      const shouldNotifyBugsnag =
        process.env.NODE_ENV !== 'test' && bugsnag.setContext && bugsnag.notify;

      if (shouldNotifyBugsnag) {
        bugsnag.setContext('Client: Request to get my preferentials details');
        bugsnag.notify(new Error(error));
      }

      return rejectWithValue({
        data: error.response.data,
        status: error.response.status,
      });
    }
  },
);

export const deleteMyPreferentials = createAsyncThunk(
  'my_preferentials/deleteMyPreferentials',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/v2/taskers/preferentials/${orderId}`);
      return response?.data;
    } catch (error) {
      const shouldNotifyBugsnag =
        process.env.NODE_ENV !== 'test' && bugsnag.setContext && bugsnag.notify;

      if (shouldNotifyBugsnag) {
        bugsnag.setContext('Client: Request to delete my preferentials');
        bugsnag.notify(new Error(error));
      }

      return rejectWithValue({
        data: error.response.data,
        status: error.response.status,
      });
    }
  },
);
