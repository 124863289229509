import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  palette: {
    ...palette,
  },
});

export default theme;
