import React from 'react';
import App from 'next/app';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import store from '@/core/store';
import theme from '@/core/theme';
import { bugsnag } from '@/core/config/bugsnag.config';

function TaskerWebApp({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </Provider>
  );
}

/**
 * TODO: Removes getInitialProps when the tasker_app sets token in webview.
 *
 * !IMPORTANT: This disables the ability to perform automatic static optimization,
 * causing every page in the app to be server-side rendered.
 * Learn more: https://nextjs.org/docs/advanced-features/automatic-static-optimization
 */
TaskerWebApp.getInitialProps = async appContext => {
  const appProps = await App.getInitialProps(appContext);
  const {
    ctx: { req, res },
  } = appContext;
  const session = req?.headers?.cookie;
  const appVersion = req?.headers['x-app-version'];
  const oldVersion = appVersion <= '2.41.1';

  if (oldVersion) {
    if (session) {
      res.setHeader('set-cookie', session);
    } else {
      bugsnag.setContext('SSR: No session found in request headers');
      bugsnag.notify(new Error(session));
    }
  }

  return { ...appProps };
};

TaskerWebApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default TaskerWebApp;
