export const convertToReal = number => {
  if (!number) {
    throw Error('please pass a valid parameter');
  }

  if (typeof number !== 'number') {
    throw Error('please use a number to run this utility function');
  }

  return number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};
