import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from '@providers/api.provider';

export const getAboutTasker = createAsyncThunk(
  'about/getAboutTasker',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/v2/taskers/me');

      return response.data;
    } catch (error) {
      if (error.response)
        return rejectWithValue({
          data: error.response.data,
          status: error.response.status,
        });

      return rejectWithValue({
        data: { message: error.message },
      });
    }
  },
);
