export const myPreferentialState = {
  preferentials: [],
  error: {
    status: null,
    message: null,
  },
  loading: false,
  preferentialDetails: {
    loading: false,
    error: {
      status: null,
      message: null,
    },
    data: {},
  },
  removePreferential: {
    loading: false,
    message: null,
    error: {
      status: null,
      message: null,
    },
    data: {},
  },
};
