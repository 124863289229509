export const convertServiceType = type => {
  if (typeof type !== 'string') {
    throw Error('please use a string value to run this utility function');
  }

  const serviceType = {
    heavy_cleaning: 'Limpeza pesada',
    remodeling_cleaning: 'Limpeza Pós-obra',
    business_cleaning: 'Limpeza comercial',
    ironing: 'Passar roupa',
    pre_moving_cleaning: 'Limpeza Pré-mudança',
    cleaning: 'Limpeza padrão',
    furniture_assembly: 'Montagem de móveis',
    express_cleaning: 'Limpeza Express',
  };

  if (Object.prototype.hasOwnProperty.call(serviceType, type)) {
    return serviceType[type];
  }

  throw Error(
    'the param passed doesn´t match in any service types, please use a existent type',
  );
};
