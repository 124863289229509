import { configureStore } from '@reduxjs/toolkit';

import { aboutSlice } from '@/app/features/about/about.slice';
import { noShowSummarySlice } from '@/app/features/no-show-summary/no-show-summary.slice';
import { myPreferentialSlice } from '@/app/features/my-preferentials/my-preferentials.slice';

export const slices = [aboutSlice, noShowSummarySlice, myPreferentialSlice];

export const composeReducers = () => {
  const currentSlices = slices.map(currentSlice => {
    return {
      [currentSlice.name]: currentSlice.reducer,
    };
  });

  return Object.assign({}, ...currentSlices);
};

const store = configureStore({
  reducer: composeReducers(),
});

export default store;
